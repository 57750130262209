import React from 'react'

export function LightboxNav({
  displayContent = '',
  clickHandler = () => {},
  extraClasses = '',
}) {
  return (
    <button
      className={`absolute text-white/[0.8] transition hover:text-white bg-black/75 hover:bg-black z-3 w-auto p-1 focus:outline-none ${extraClasses}`}
      onClick={clickHandler}
      onTouchEnd={clickHandler}
      style={{
        minWidth: '34px',
        minHeight: '34px',
      }}
    >
      {displayContent}
    </button>
  )
}
