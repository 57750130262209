import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export default function ProjectImage({
  index = 0,
  image,
  handleImageClick,
  limitHeight = false,
  size = 'md',
}) {
  const imageNode = getImage(image)
  return (
    <GatsbyImage
      image={imageNode}
      objectFit="cover"
      alt=""
      className={`cursor-pointer ${
        limitHeight ? size : ''
      }`}
      onClick={() => {
        handleImageClick && handleImageClick(index)
      }}
    />
  )
}
