import React, { useEffect, useState, useRef } from 'react'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import { graphql, Link } from 'gatsby'
import Lightbox from '../../components/lightbox'
import ProjectImage from '../../components/project-image'
import './project.scss'
import { IoCloseOutline } from 'react-icons/io5'

const ProjectNav = ({ targetProject, text = '' }) => {
  if (targetProject) {
    return (
      <Link
        to={`/projects/` + targetProject.slug}
        className="font-display font-bold text-lg md:text-xl hover:text-black"
      >
        {text}
      </Link>
    )
  }
  return (
    <span className="font-display font-bold text-lg md:text-xl text-gray-300">
      {text}
    </span>
  )
}

export default function ContentfulProject(props) {
  const projectData = props.data.contentfulProject
  const { title, images } = projectData
  const plainText = projectData.mainText?.internal.content
  const richText = projectData.description
  const [focusedImage, setFocusedImage] = useState(0)
  const [lightboxOpen, setLightboxOpen] = useState(false)
  const handleFullScreen = (i) => {
    setFocusedImage(i)
    setLightboxOpen(true)
  }

  const splashTitle = useRef(null)
  const [mainVisible, setMainVisible] = useState(false)
  useEffect(() => {
    if (document !== undefined) {
      document.fonts.ready.then(() => {
        setTimeout(() => {
          splashTitle.current.style.opacity = 1
        }, 10)
      })
      document.body.style.position = 'fixed'
      document.body.style.overflow = 'scroll'
      setTimeout(() => {
        setMainVisible(true)
        document.body.style.position = 'static'
        document.body.style.overflow = 'auto'
      }, 1500)
    }
  }, [])

  let prevSlug = null
  let nextSlug = null
  props.data.contentfulProjectGallery.projects.map((project, index, array) => {
    if (project.slug === projectData.slug) {
      prevSlug = array[index - 1]
      nextSlug = array[index + 1]
    }
  })

  return (
    <Layout container={'fluid'}>
      <Seo title={title} />
      <div className={'project-single'}>
        <div
          className={
            'splash-title fixed z-10 flex-col top-0 left-0 w-full mx-auto py-28 px-4 min-h-screen flex justify-center items-center space-y-4 text-center bg-white opacity-0 transition-opacity'
          }
          ref={splashTitle}
        >
          <h2 className="text-3xl md:text-5xl lg:text-6xl font-display font-bold uppercase">
            {title}
          </h2>
        </div>
        <div
          className={`main-content pt-16 lg:pt-36 lg:max-w-3xl xl:max-w-5xl 2xl:max-w-7xl mx-auto relative z-20 bg-white transition-opacity duration-500 ${
            mainVisible
              ? 'opacity-100 overflow-auto h-auto'
              : 'opacity-0 overflow-y-visible h-0'
          }`}
        >
          <div className="images space-y-10 md:space-y-18 lg:space-y-24 px-2">
            {images.map((image, index) => {
              return (
                <div className="relative w-full" key={index}>
                  <ProjectImage
                    index={index}
                    image={image}
                    handleImageClick={handleFullScreen}
                    limitHeight={true}
                    size={'lg'}
                  />
                </div>
              )
            })}
          </div>
          <div className="relative flex-col p-4 pt-16 pb-20 flex justify-center items-center space-y-4 min-h-screen">
            <div className="w-full max-w-xl mx-auto">
              <h1 className="text-2xl md:text-4xl font-display font-bold uppercase mb-4 text-center">
                {title}
              </h1>
              {richText ? (
                <div className={'project-description'}>
                  {renderRichText(richText, {
                    renderMark: {
                      [MARKS.BOLD]: (text) => (
                        <b className="font-bold">{text}</b>
                      ),
                    },
                    renderNode: {
                      [BLOCKS.PARAGRAPH]: (node, children) => {
                        return <p className="text-justify mb-2">{children}</p>
                      },
                      [BLOCKS.UL_LIST]: (node, children) => {
                        return (
                          <ul className="list-disc pl-5 text-center">
                            {children}
                          </ul>
                        )
                      },
                      [BLOCKS.OL_LIST]: (node, children) => {
                        return <ol className="list-decimal pl-5">{children}</ol>
                      },
                      [BLOCKS.LIST_ITEM]: (node, children) => {
                        return <li className="">{children}</li>
                      },
                      [INLINES.HYPERLINK]: (node, children) => {
                        const { uri } = node.data
                        return (
                          <a href={uri} className="underline">
                            {children}
                          </a>
                        )
                      },
                      [BLOCKS.HEADING_1]: (node, children) => {
                        return (
                          <h1 className={'text-5xl font-bold'}>{children}</h1>
                        )
                      },
                      [BLOCKS.HEADING_2]: (node, children) => {
                        return (
                          <h2 className={'text-4xl font-bold'}>{children}</h2>
                        )
                      },
                      [BLOCKS.HEADING_3]: (node, children) => {
                        return (
                          <h3 className={'text-3xl font-bold'}>{children}</h3>
                        )
                      },
                      [BLOCKS.HEADING_4]: (node, children) => {
                        return (
                          <h4 className={'text-2xl font-bold'}>{children}</h4>
                        )
                      },
                      [BLOCKS.HEADING_5]: (node, children) => {
                        return (
                          <h5 className={'text-xl font-bold'}>{children}</h5>
                        )
                      },
                      [BLOCKS.HEADING_6]: (node, children) => {
                        return (
                          <h6 className={'text-lg font-bold'}>{children}</h6>
                        )
                      },
                    },
                  })}
                </div>
              ) : (
                <p className={'text-center'}>{plainText}</p>
              )}
            </div>
            <div className="absolute bottom-0 p-4 py-6 flex w-full justify-between">
              <ProjectNav targetProject={prevSlug} text={'PREVIOUS'} />
              <ProjectNav targetProject={nextSlug} text={'NEXT'} />
            </div>
            <div className="fixed text-center bottom-0 py-4">
              <Link
                to={`/#${projectData.slug}`}
                className="inline-flex items-center justify-center p-2 hover:text-black hover:bg-gray-100/40 focus:outline-none text-3xl"
                title="return to projects"
              >
                <IoCloseOutline />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Lightbox
        lightboxOpen={lightboxOpen}
        setLightboxOpen={setLightboxOpen}
        images={images}
        focusedImage={focusedImage}
        setFocusedImage={setFocusedImage}
      />
    </Layout>
  )
}

export const query = graphql`
  query SingleProject($id: String) {
    contentfulProjectGallery(galleryTitle: { eq: "Homepage Gallery" }) {
      projects {
        slug
      }
    }
    contentfulProject(id: { eq: $id }) {
      spaceId
      node_locale
      title
      slug
      images {
        id
        gatsbyImageData(placeholder: DOMINANT_COLOR)
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
      }
      mainText {
        internal {
          content
        }
      }
      description {
        raw
      }
    }
  }
`
