/**
 * Returns 'previous', 'active' or 'next' to use as classes.
 * @param index
 * @param activeIndex
 * @param arrayLength
 * @returns {string|string}
 */
const loopNavClass = (index, activeIndex, arrayLength) => {
    switch (activeIndex) {
        case index - 1:
            return "next"
        case index:
            return "active"
        case index + 1:
            if(arrayLength === 2) return "next"
            return "previous"
        case 0:
            return index === arrayLength - 1 ? "previous" : ""
        case arrayLength - 1:
            return index === 0 ? "next" : ""
        default:
            return ""
    }
}

const navCollection = (current, collectionLength, direction) => {
    let nextIndex = current + (1 * direction)
    if (direction === 1 && nextIndex === collectionLength) nextIndex = 0
    if (direction === -1 && nextIndex === -1) nextIndex = collectionLength - 1
    return nextIndex;
}

export { loopNavClass, navCollection };