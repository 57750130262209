import React, { useEffect, useRef, useState } from 'react'
import panzoom from 'panzoom'
import { navCollection } from './utilities'
import { LightboxNav } from './partials/lightbox/LightboxNav'
import {
  IoClose,
  IoArrowBackOutline,
  IoArrowForwardOutline,
} from 'react-icons/io5'

export default function Lightbox({
  lightboxOpen,
  setLightboxOpen,
  setFocusedProject = null,
  images,
  focusedImage,
  setFocusedImage,
}) {
  const imageElement = useRef(null)
  const [srcSize, setSrcSize] = useState({ width: 0, height: 0 })
  const [imgLoaded, setImgLoaded] = useState(false)

  useEffect(() => {
    if (images)
      setSrcSize({
        width: images[focusedImage]?.file.details.image.width,
        height: images[focusedImage]?.file.details.image.height,
      })
  }, [images, focusedImage])

  const close = () => {
    setLightboxOpen(false)
    if (setFocusedProject) setFocusedProject(null)
    setFocusedImage(null)
  }

  const [pz, setPz] = useState(null)

  const navLightboxImage = (direction) => {
    pz && pz.dispose()
    setPz(null)
    setImgLoaded(false)
    setTimeout(() => {
      setFocusedImage(navCollection(focusedImage, images.length, direction))
    }, 300)
  }

  const imageLoadedHandler = () => {
    setImgLoaded(true)
    const img = imageElement.current
    const windowSize = {
      width: document.body.offsetWidth,
      height: window.innerHeight,
    }
    let initZoom =
      srcSize.width / srcSize.height > windowSize.width / windowSize.height
        ? windowSize.width / srcSize.width
        : windowSize.height / srcSize.height
    initZoom = initZoom > 1 ? 1 : initZoom
    const pz = panzoom(img, {
      minZoom: 0.1,
      maxZoom: 1,
      initialZoom: initZoom,
      transformOrigin: { x: 0.5, y: 0.5 },
      onTouch: function (e) {
        e.preventDefault()
        if (
          e.target.classList.contains('close-btn') ||
          e.target.classList.contains('close-lightbox')
        ) {
          close()
        }
      },
    })
    pz.moveTo(
      (windowSize.width - srcSize.width * initZoom) / 2,
      (windowSize.height - srcSize.height * initZoom) / 2
    )
    setPz(pz)
  }

  // @todo handle keypres to navigate with keyboard: arrows and escape

  return (
    <div
      id="lightbox"
      className="fixed top-0 left-0 w-full h-full z-50 bg-black"
      style={{ display: lightboxOpen ? 'block' : 'none' }}
    >
      <div className="area -- h-full">
        <button
          className="btn textless close-lightbox -- absolute z-1 top-0 left-0 w-full h-full text-left border-0 p-0 bg-transparent focus:outline-none cursor-default"
          style={{ textIndent: '-9999px' }}
          onClick={close}
        >
          close lightbox
        </button>
        <img
          src={images && lightboxOpen ? images[focusedImage].file.url : ''}
          alt=""
          ref={imageElement}
          className={`lightbox-image -- relative z-2 max-w-none transition-opacity duration-300 cursor-move ${
            imgLoaded ? 'opacity-100' : 'opacity-0'
          }`}
          onLoad={imageLoadedHandler}
        />
        <LightboxNav
          displayContent={
            <IoClose className="inline-block" style={{ fontSize: '1.5em' }} />
          }
          clickHandler={() => {
            close()
          }}
          extraClasses={'top-4 right-4'}
        />
        <LightboxNav
          displayContent={
            <IoArrowBackOutline
              className="inline-block"
              style={{ fontSize: '1.5em' }}
            />
          }
          clickHandler={() => {
            navLightboxImage(-1)
          }}
          extraClasses={'bottom-4 left-4'}
        />
        <LightboxNav
          displayContent={
            <IoArrowForwardOutline
              className="inline-block"
              style={{ fontSize: '1.5em' }}
            />
          }
          clickHandler={() => {
            navLightboxImage(1)
          }}
          extraClasses={'bottom-4 right-4'}
        />
      </div>
    </div>
  )
}
